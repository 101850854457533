import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import usePetStorage from '../../managers/petsManager';

const PetProfileCarousel = ({ pet }) => {

  const { getPetAgeByDOB } = usePetStorage();
  const [petAge, setPetAge] = useState('Mysterious Age');
  useEffect(() => {
    if (pet?.age) {
      setPetAge(`${pet.age} ${pet.ageType}`);
    }
    if (pet?.date_of_birth) {
      const petAge = getPetAgeByDOB(pet.date_of_birth);
      setPetAge(petAge);
    }
  }, [pet?.date_of_birth, pet?.age, pet?.ageType, getPetAgeByDOB]);

  // List of children to be displayed in the carousel
  const childrenList = [
    {
      title: 'AGE',
      content: petAge || 'Mysterious Age',
      background: "linear-gradient(180deg, #EEF5FF 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/cake.svg`,
    },
    {
      title: 'BIRTHDATE',
      content: pet?.date_of_birth || 'Mysterious Birthdate',
      background: "linear-gradient(180deg, #FAEBFF 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/balloon.svg`,
    },
    {
      title: 'ADDRESS',
      content: pet?.address || 'Mysterious Address',
      background: "linear-gradient(180deg, #EEFFFE 0%, #FFFFFF 100%)",
      icon: `${process.env.PUBLIC_URL}/location.svg`,
    }
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        alignItems: 'center',
        '& > *': {
          flexShrink: 0,
          marginRight: '16px', // Spacing between cards
        },
        '&::-webkit-scrollbar': { display: 'none' }, // Optional: hide scrollbar
      }}
    >
      {/* Cards list from prop */}
      {childrenList.map((child, index) => (
        <Card key={index} sx={{
          width: 'fit-content', borderRadius: "8px", marginTop: "24px", marginBottom: "24px",
          boxShadow: "0px 2px 8px 0px #1E07511F",

        }}>
          <CardContent sx={{ background: child.background, display: 'flex', padding: "12px 16px 12px 12px !important", alignContent: "center" }}>
            <img style={{ marginRight: '16px' }} src={child.icon} alt="icon" width="40" height="40" />
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <Typography sx={{
                paddingBottom: "4px",
                textAlign: "left",
                color: '#7D7D97',
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "18.6px",
              }}>
                {child.title}
              </Typography>
              <Typography sx={{
                paddingBottom: "4px",
                textAlign: "left",
                color: '#1F1F2A',
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "21.7px",
              }}>
                {child.content}
              </Typography>
            </div>
          </CardContent>
        </Card>
      ))}

    </Box>
  );
};

export default PetProfileCarousel;
