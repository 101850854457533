import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 650,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    sectionTitle: {
        paddingTop: '60%',
        paddingBottom: '2%',
    },
    inputTextBox: {
        padding: '16px',
        marginTop: '8px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    detailSection: {
        marginBottom: '24px',
    },
    googleButton: {
        width: '100%',
        padding: '16px',
        borderRadius: '8px',
        fontSize: '16px',
        lineHeight: '24.8px',
        fontWeight: 600,
    },
}));

const PasswordForm = ({ email, setPassword }) => {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    
    return (
        <>
            <div className={classes.detailSection}>

                {/* main title */}
                <Typography variant="h4" component="div" className={classes.sectionTitle}>
                    <span style={{fontWeight: 600}}>Enter password</span>
                </Typography>

                {email && (
                    <Typography align="left" className={classes.iconText}>
                        <span style={{verticalAlign: 'top'}}>for <span style={{
                            strokeWidth: '0.5px',
                            fontWeight: 500,
                        }}>{email}</span></span>
                    </Typography>
                )}

                {/* password field */}
                <TextField
                    align="left"
                    type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.inputTextBox}
                    placeholder="Enter your password"
                    sx={{
                        cursor: 'text',// : 'pointer',
                        color: '#1F1F2A',// : '#7D7D97',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        marginTop: '32px',
                        fontWeight: 500,
                    }}
                    InputProps={{ // <-- This is where the toggle button is added.
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                style={{ color: '#7019FF' }}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    slotProps={{
                        inputLabel: {
                            shrink: false,
                        }
                    }}
                />

                <Typography align="right" className={classes.iconText} style={{marginTop: '16px'}}>
                    <a href="/forgot-password" style={{ textDecoration: 'none', color: '#7019FF' }}>
                        <span style={{fontWeight: 600}}>Forgot password?</span>
                    </a>
                </Typography>

            </div>
        </>
    );
};

export default PasswordForm;
