import React, { useState } from 'react';
import { Chip, Avatar, IconButton, Typography, Tabs, Tab, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import { paths, PetsTagsStyle as TagsStyle } from '../../data/constants.js';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,
        width: '100dvw',
    },
    title: {
        "&.MuiTypography-root": {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1,
            textAlign: 'center',
            alignItems: 'center',
        }
    },
    icons: {
        color: "#1F1F2A",

    },
    tabsContainer: {
        overflowX: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tabTitle: {
        '&.MuiTab-root': {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '17.07px',
            // lowercase
            textTransform: 'none',
        }
    },
    petHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '48px',
    },
    dummySpace: {
        width: '40px', // same width as backButton to balance the space
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
        justifyContent: 'center',
    },
    chip: {
        maxWidth: 220,
        height: 29,
        borderRadius: '6px !important',
        pointerEvents: 'none',
        "&.MuiChip-root": {
            padding: '6px 8px',
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '17.07px',
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },
    petName: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '12px',
        "&.MuiTypography-root": {
            // 24px
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '33.6px',
            color: "#1F1F2A"
        },
    },
    petAvatar: {
        display: 'flex',
        alignItems: 'center',
        // space between
        justifyContent: 'space-between',
        // height: 25,
    },
    petAvatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
        marginBottom: '8px',
        padding: '0 4px'
    },
}));

const PetProfileFormHeader = ({ title, petId, setTabIndex, tabIndex, onTabChange, hasTabs=true }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (!petId) {
            navigate(paths.pets);
            return;
        }
        navigate(paths.petProfile(petId));
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        onTabChange(newValue);
    };

    return (
        <div className={classes.petHeaderContainer}>
            <Grid container alignItems="center" className={classes.titleContainer}>
                {/* Back Arrow Icon on the Start */}
                <Grid item xs={2}>
                    <IconButton onClick={handleBackClick} className={classes.icons}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>

                {/* Title in the Center */}
                <Grid item xs={8} container justifyContent="center">
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                </Grid>

                {/* Spacer for Layout Balance */}
                <Grid item xs={2} />
            </Grid>
            {hasTabs && (
                <div className={classes.tabsContainer}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        TabIndicatorProps={{ style: { background: "#1F1F2A" } }}
                        variant="scrollable"
                        scrollButtons="auto" // Enable scroll buttons for overflow
                    >
                        <Tab className={classes.tabTitle} label="Basic Info" />
                        <Tab className={classes.tabTitle} label="Personality" />
                        <Tab className={classes.tabTitle} label="Parents" />
                        <Tab className={classes.tabTitle} label="Social Links" />
                    </Tabs>
                </div>
            )}
        </div>
    );
};

export default PetProfileFormHeader;
