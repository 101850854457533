import React from 'react';
import { useDropzone } from 'react-dropzone';
import { convertToBase64 } from '../../utils/petUtils';
import { styled } from '@mui/material/styles';
import S3Handler from '../../utils/s3';

const DropzoneContainer = styled('div')(({ theme, isEditing }) => ({
    width: '120px',
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    justifyContent: 'center',
    border: '2px solid #FFFFFF',
    borderRadius: "12px",
    background: 'var(--Light-Grey, #F6F7FB)',
    cursor: isEditing ? 'pointer' : 'not-allowed',  // Conditionally set cursor
    marginBottom: '16px',
    boxShadow: '0px 2px 12px 0px #00000026',
    '& img': {
        width: '21px',
        height: '19px',
    },
}));

const DropzoneImage = ({ formik, isEditing = false }) => {
    // Conditionally handle onDrop based on `isEditing`
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        disabled: !isEditing,  // Disable dropzone if not editing
        onDrop: async (acceptedFiles) => {
            if (!isEditing) return;  // Prevent function if not editing
            const imageFile = acceptedFiles[0];
            try {
                const base64 = await convertToBase64(imageFile);  // Await the conversion
                const plainBase64 = base64.split(',')[1]; // Remove the "data:image/png;base64," prefix

                // get date 
                const date = new Date();
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const dateSubstr = `${year}${month}${day}`;

                // Upload the image to S3
                const s3 = new S3Handler();
                const s3Url = await s3.uploadBase64Image(
                    plainBase64, 
                    `user-upload/${dateSubstr}`, 
                    imageFile.name, 
                    imageFile.type
                );

                formik.setFieldValue('photo', s3Url);  // Set the formik field value
            } catch (error) {
                console.error("Failed to convert image to base64", error); // Handle any errors
            }
        },
    });

    return (
        <DropzoneContainer {...getRootProps()} isEditing={isEditing}>
            <input {...getInputProps()} disabled={!isEditing} />  {/* Disable input if not editing */}
            {formik.values.photo ? (
                <img src={formik.values.photo} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 12, border: 2 }} />
            ) : (
                <img
                    src={`${process.env.PUBLIC_URL}/profileIcons/uploadImg.svg`}
                    alt="Upload Icon"
                />
            )}
        </DropzoneContainer>
    );
};

export default DropzoneImage;
