


export const setPets = async (pets) => {
    // clear localStorage
    localStorage.removeItem('pets');

    const restructured = {};
    
    pets.forEach((pet) => {
        restructured[pet.id] = {
            ...pet,
        }
    });

    localStorage.setItem(
        'pets', 
        JSON.stringify(restructured)
    );
}