
// TODO Detailed Summary
export const DETAILED_SUMMARY_FORMAT = `
{
  "sessionTitle": "Session Title (e.g., Consultation with Mr. Whiskers, Checkup for Max)",
  "sections": [
    {
      "title": "HISTORY",
      "items": [
        { "text": "Reason for visit: Reason for visit (e.g., F3 vaccination)"},
        { "text": "Owner's requests: Owner's requests (e.g., nail clipping + worm tablets)"},
        { "text": "Concern: Specific concern (e.g., lump on the back of the neck, about 1 cm in diameter, intradermal)"},
        { "text": "Currently on: Current medications (e.g., Bravecto, Pro Fender)"},
        { "text": "EDUF: EDUF findings (e.g., normal)"},
        { "text": "Negative findings: Negative findings (e.g., no coughing, sneezing, vomiting, or diarrhea)"},
        { "text": "Energy levels: Energy levels (e.g., fine)"}
      ]
    },
    {
      "title": "EXAM",
      "items": [
        { "text": "BCS: Body Condition Score (e.g., 7/9) HR: Heart Rate (e.g., 110) T: Temperature (e.g., 37.6)"},
        { "text": "Skin: Skin findings (e.g., 1 cm lump on the back of the neck, intradermal)"},
        { "text": "Dental findings: Dental findings (e.g., Grade 2, needs polish and scale)"}
      ]
    },
    {
      "title": "DIAGNOSIS",
      "items": [
        { "text": "Diagnosis 1: Diagnosis 1 (e.g., Grade 2 dental disease)"},
        { "text": "Diagnosis 2: Diagnosis 2 (e.g., Intradermal lump on the back of the neck)"}
      ]
    },
    {
      "title": "PLAN",
      "items": [
        { "text": "Plan 1: Plan 1 (e.g., Nails clipped)"},
        { "text": "Plan 2: Plan 2 (e.g., Wormed with 1.5 fenpral)"},
        { "text": "Plan 3: Plan 3 (e.g., F3 vaccination given)"}
      ]
    }
  ]
}

`

export const SIMPLE_SUMMARY_FORMAT = `{
  "sessionTitle": "Session Title (e.g., Consultation with Mr. Whiskers, Checkup for Max)",
  "sections": [
    {
      "title": "HISTORY",
      "items": [
        { "text": "Reason for visit: Reason for visit (e.g., vaccination, nail clipping)"},
        { "text": "Main concern: Main concern (e.g., lump on neck)"},
        { "text": "Medications: Medications (e.g., Bravecto)"},
        { "text": "General health: General health (e.g., no coughing, vomiting)"}
      ]
    },
    {
      "title": "EXAM",
      "items": [
        { "text": "BCS: Body Condition Score (e.g., 7/9) Vitals: HR: 110, Temp: 37.6"},
        { "text": "Key findings: 1 cm lump on neck, Grade 2 dental"}
      ]
    },
    {
      "title": "DIAGNOSIS",
      "items": [
        { "text": "Primary diagnosis: Primary diagnosis (e.g., dental disease, lump)"}
      ]
    },
    {
      "title": "PLAN",
      "items": [
        { "text": "Plan 1: Plan 1 (e.g., Nails clipped)"},
        { "text": "Plan 2: Plan 2 (e.g., Wormed with 1.5 fenpral)"},
        { "text": "Plan 3: Plan 3 (e.g., F3 vaccination given)"}
      ]
    }
  ]
}
`
export const paths = {
  home: "/",
  notes: "/notes",
  session: (sessionId = ":sessionId") => `/notes/session/${sessionId}`,
  pets: "/pets",
  petProfile: (profileId = ":profileId") => `/pets/profile/${profileId}`,
  petCreateEdit: '/pets/edit',
  petCreate: '/pets/create',
  petCreationFlow: "/pets/new-parent",
  petEntryEdit: (profileId = ":profileId", entryID = ":entryID") => `/pets/${profileId}/entry/edit/${entryID}`,
  petEntryCreate: (profileId = ":profileId") => `/pets/${profileId}/entry/create`,
  verifyEmail: "/verify-email",
  login: "/login",
  password: "/password",
  resetPassword: "/reset-password",
  passwordResetSuccess: "/reset-password-success",
  passwordSavedSuccess: "/password-saved-success",
  unregistered: "/unregistered",
};

export const apiEndpoints = {
  diary: {
    // accept profileId
    create: 'pet/diary/create',
    update: 'pet/diary/update',
    fetch: 'pet/diary/retrieve',
  }
}

export const PetsTagsStyle = {
  type: {
    dog: {
      backgroundColor: '#FFF3E7',
      color: '#FF9533',
    },
    cat: {
      backgroundColor: '#FFF3E7',
      color: '#FF9533',
    },
  },
  breed: {
    backgroundColor: '#EEF5FF',
    color: '#1877F2',
  },
  sex: {
    male: {
      backgroundColor: '#F3F1FF',
      color: '#7019FF',
    },
    female: {
      backgroundColor: '#FAEBFF',
      color: '#CA33FF',
    },
  },
  default: {
    backgroundColor: '#F0F0F0',
    color: '#A0A0A0',
  }
}

export const DiaryCardTagsStyle = {
  type: {
    "health-and-wellness": {
      backgroundColor: '#F3F1FF',
      color: '#7019FF',
    },
    memory: {
      backgroundColor: '#F3F1FF',
      color: '#7019FF',
    },
    diet: {
      backgroundColor: '#FAEBFF',
      color: '#CA33FF',
    },
    grooming: {
      backgroundColor: '#F3F1FF',
      color: '#7019FF',
    },
    others: {
      backgroundColor: '#FAEBFF',
      color: '#CA33FF',
    },
  },
  default: {
    backgroundColor: '#F0F0F0',
    color: '#A0A0A0',
  }
}

export const diaryEntryTypes = {
  'health-and-wellness': 'Health & Wellness',
  'diet': 'Diet',
  'memory': 'Memory',
  'grooming': 'Grooming',
  'others': 'Others',
}
