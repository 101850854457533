import { S3 } from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

class S3Handler {
    constructor() {
        this.s3 = new S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_REGION
        });
        
        this.bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
    }
    
    async uploadBase64Image(base64Image, fileDir, fileName, contentType = 'image/jpeg') {
        const buffer = Buffer.from(base64Image, 'base64');
        const uuidKey = `${fileDir}/${uuidv4()}-${fileName}`;

        const params = {
            Bucket: this.bucketName,
            Key: uuidKey,
            Body: buffer,
            ContentType: contentType,
            ACL: 'public-read' // Enable ACL
        };
        
        try {
            const data = await this.s3.upload(params).promise();
            return data.Location;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    }
}

export default S3Handler;
