import { useState } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';

const api = new ApiClient();

const useStorage = (collectionName, apiEndpoints = {}) => {
  const [storedData, setStoredData] = useState(() => {
    try {
      const data = window.localStorage.getItem(collectionName);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      console.error(`Error initializing ${collectionName} from localStorage:`, error);
      return {};
    }
  });

  const syncLocalStorage = (data) => {
    try {
      localStorage.setItem(collectionName, JSON.stringify(data));
    } catch (error) {
      console.error(`Error syncing ${collectionName} to localStorage:`, error);
    }
  };

  const setValue = (updateFunc) => {
    setStoredData((prevData) => {
      const updatedData = updateFunc(prevData);
      syncLocalStorage(updatedData);
      return updatedData;
    });
  };

  // Fetch Data by Key
  const fetchData = async (key, params = {}) => {
    try {
      const response = await api.get(`${apiEndpoints.fetch}?${key}=${params[key]}`);
      if (response.error) {
        console.error(`Error fetching ${collectionName}:`, response.error);
        toast.error(`Error fetching ${collectionName}`);
        return;
      }

      const formattedData = response.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setStoredData(formattedData);
      syncLocalStorage(formattedData);
    } catch (error) {
      console.error(`Error fetching ${collectionName}:`, error);
      toast.error(`Error fetching ${collectionName}`);
    }
  };

  // Add Item
  const addItem = async (refId, newItem) => {
    try {
      // refId like petID, userID, etc.
      const response = await api.post((`${apiEndpoints.create}/${refId}`), newItem);
      if (response.error || !response.id) {
        toast.error(`Error adding ${collectionName}`);
        return;
      }

      setValue((prevData) => ({
        ...prevData,
        [response.id]: { ...newItem, id: response.id },
      }));

      toast.success(`${collectionName} added successfully`);
    } catch (error) {
      console.error(`Error adding ${collectionName}:`, error);
      toast.error(`Error adding ${collectionName}`);
    }
  };

  // Update Item
  const updateItem = async (itemId, newData) => {
    try {
      const response = await api.put(`${apiEndpoints.update}/${itemId}`, newData);
      if (response.error) {
        toast.error(`Error updating ${collectionName}`);
        return;
      }

      setValue((prevData) => ({
        ...prevData,
        [itemId]: { ...prevData[itemId], ...newData },
      }));

      toast.success(`${collectionName} updated successfully`);
    } catch (error) {
      console.error(`Error updating ${collectionName}:`, error);
      toast.error(`Error updating ${collectionName}`);
    }
  };

  // Delete Item
  const deleteItem = async (itemId) => {
    try {
      const response = await api.delete(`${apiEndpoints.delete}/${itemId}`);
      if (response.error) {
        toast.error(`Error deleting ${collectionName}`);
        return;
      }

      setValue((prevData) => {
        const { [itemId]: _, ...rest } = prevData;
        return rest;
      });

      toast.success(`${collectionName} deleted successfully`);
    } catch (error) {
      console.error(`Error deleting ${collectionName}:`, error);
      toast.error(`Error deleting ${collectionName}`);
    }
  };

  // Local Data Accessors
  const getItemById = (itemId) => storedData[itemId] || null;
  const getAllItems = () => Object.values(storedData);

  return {
    data: storedData,
    fetchData,
    addItem,
    updateItem,
    deleteItem,
    getItemById,
    getAllItems,
  };
};

export default useStorage;
