import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import DefaultHeader from '../components/Header/defaultHeader.jsx';
import PasswordForm from '../components/Auth/PasswordForm.jsx';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { setPets } from '../utils/storage';
import ApiClient from '../utils/client';

const api = new ApiClient();

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const Password = () => {
    const classes = useStyles();

    const [disabled, setDisabled] = React.useState(true);

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        if (!auth) {
            toast.error('Failed to capture email');
        }

        const { data } = JSON.parse(auth);
        if (data?.email) {
            setEmail(data.email);
        }
    }, []);

    useEffect(() => {
        if (password !== '' && password.length >= 8) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [password]);

    const handleLogin = async () => {
        setDisabled(true);
    
        if (!api) throw new Error('API client not found');
    
        if (!email || !password) {
            toast.error('Invalid request');
            setDisabled(false);
            return;
        }
    
        const response = await api.post(`cms/auth/login`, {
            email,
            password,
        });

        if (!response) {
            toast.error('Failed to check password');
            setDisabled(false);
            return;
        }
        
        if (response?.status !== 'success') {
            toast.error("Login failed");
            setDisabled(false);
            return;
        }

        if (!response?.customer) {
            toast.error('Customer not found');
            setDisabled(false);
            return;
        }

        // clear localStorage
        localStorage.setItem('auth', JSON.stringify({ 
            state: 'logged-in',
            data: {
                email,
            },
        }));

        localStorage.setItem('customer', JSON.stringify(response.customer || {}));

        // set in local storage
        await setPets(response.pets || []);

        // redirect
        window.location.href = '/pets';
    }

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <DefaultHeader />
            </Header>
            <Container maxWidth="lg">
                <div className={classes.contentContainer}>
                    <PasswordForm 
                        email={email} 
                        setPassword={setPassword} 
                    />
                </div>
            </Container>
            <div style={{ 
                position: 'fixed', 
                bottom: '0', 
                width: '100%', 
                padding: '16px', 
                background: '#fff', 
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' 
            }}>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={undefined}
                    sx={{
                        width: '30%',
                        padding: '16px',
                        borderRadius: '8px',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        color: '#000',
                        textTransform: 'none',
                    }}
                    >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.socialButton}
                    onClick={handleLogin}
                    sx={{
                        width: '65%',
                        padding: '16px',
                        marginLeft: '16px',
                        background: disabled ? '#A0A0A0' : '#7019FF',
                        borderRadius: '8px',
                        color: '#fff',
                        fontSize: '16px',
                        lineHeight: '24.8px',
                        fontWeight: 600,
                        textTransform: 'none',
                    }} disabled={disabled}>
                    Login
                </Button>
            </div>
        </>
    );
};

export default Password;
