import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Typography, Button, Container, TextField, Chip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { paths, diaryEntryTypes } from '../../data/constants.js';
import Header from '../Header/header';
import PetProfileFormHeader from '../Header/petProfileFormHeader';
import ResponsiveDatePickers from '../DatePicker/datePicker.jsx';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 0 100px 0px',
    },
    formItem: {
        marginBottom: '16px',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
    },
    buttonGroup: {
        display: 'flex',
        position: 'fixed',
        bottom: '0',
        height: 88,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        maxWidth: '600px',
        width: '100%',
        padding: "20px",
        background: "#fff",
        "& > button": {
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.5px",
            textTransform: "none",
        },
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
}));

const DiaryEntryForm = ({ initialValues, onSubmit }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState(initialValues?.category || '');

    const formik = useFormik({
        initialValues: {
            date: initialValues?.date || '',
            description: initialValues?.description || '',
            category: initialValues?.category || '',
        },
        validationSchema: Yup.object({
            date: Yup.date().required('Date is required'),
            description: Yup.string().required('This field is required'),
            category: Yup.string().required('At least one category is required'),
        }),
        onSubmit: onSubmit,
    });

    const handleCategoryChange = (category) => {
        setSelectedCategories(category);
        formik.setFieldValue('category', category);
    };

    const handleDiscard = () => {
        formik.resetForm();
        navigate(paths.petProfile(initialValues?.petID));
    };

    useEffect(() => {
        if (formik.isSubmitting && formik.errors) {
            const errorKeys = Object.keys(formik.errors);
            if (errorKeys.length > 0) {
                const firstErrorKey = errorKeys[0];
                const errorElement = document.querySelector(`[name="${firstErrorKey}"]`);
                if (errorElement) {
                    errorElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [formik.isSubmitting, formik.errors]);

    return (
        <div>
            <Header>
                <PetProfileFormHeader
                    title={initialValues?.entryID ? 'Edit diary entry' : 'New diary entry'}
                    petId={initialValues?.petID || null}
                    hasTabs={false}
                />
            </Header>
            <Container maxWidth="lg" sx={{ padding: "0 20px" }}>
                <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
                    <div style={{ width: "100%" }}>
                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    Category
                                </Typography>
                            </div>
                            <div style={{ display: "flex", overflowX: 'scroll', 
                                // hide scrollbar
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                msOverflowStyle: 'none',
                                scrollbarWidth: 'none',
                                gap: "8px",

                             }}>
                                {Object.entries(diaryEntryTypes).map(([category, name]) => (
                                    <Button
                                        key={category.toLowerCase()}
                                        value={category.toLowerCase()}
                                        variant="outlined"
                                        onClick={() => handleCategoryChange(category.toLowerCase())}
                                        sx={{
                                            width: "fit-content",
                                            height: "40px",
                                            padding: "0px 16px 0px 16px",
                                            gap: "8px",
                                            borderRadius: "24px",
                                            border: "1px solid",
                                            color: "#1F1F2A",
                                            borderColor: selectedCategories === category.toLowerCase() ? "#7019FF" : "#E3E8FF",
                                            fontWeight: selectedCategories === category.toLowerCase() ? 600 : 500,
                                            color: selectedCategories === category.toLowerCase() ? "#7019FF" : "#7D7D97",
                                            fontSize: 16,
                                            lineHeight: "24.8px",
                                            textTransform: "none",
                                        }}
                                    >
                                        {name}
                                    </Button>
                                ))}
                                {formik.errors.category && (
                                    <Typography color="error">{formik.errors.category}</Typography>
                                )}
                            </div>
                        </div>

                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    Date
                                </Typography>
                            </div>
                            <ResponsiveDatePickers
                                name="date"
                                value={formik.values.date || null}
                                onChange={(key, value) => {
                                    const formattedValue = value ? new Date(value).toLocaleDateString('en-CA') : null;
                                    formik.setFieldValue(key, formattedValue);
                                }}
                                error={formik.touched.date && Boolean(formik.errors.date)}
                                helperText={formik.touched.date && formik.errors.date}
                                // limit the date to today
                                maxDate={new Date()}
                                format="dd/MM/yyyy"
                            />
                            {
                                formik.errors.date && (
                                    <Typography color="error">{formik.errors.date}</Typography>
                                )
                            }
                        </div>

                        <div className={classes.detailSection}>
                            <div className={classes.iconContainer}>
                                <Typography align="left" className={classes.iconText}>
                                    What happened?
                                </Typography>
                            </div>
                            <TextField
                                name="description"
                                multiline
                                // border color on blur
                                variant="outlined"
                                rows={4}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                // error={formik.touched.description && Boolean(formik.errors.description)}
                                // helperText={formik.touched.description && formik.errors.description}
                                className={classes.inputTextBox}
                                placeholder='e.g. Snowie got a jab and is feeling down today'
                            />
                            {
                                formik.errors.description && (
                                    <Typography color="error">{formik.errors.description}</Typography>
                                )
                            }
                        </div>

                        <div className={classes.buttonGroup}>
                            <Button
                                variant="outlined"
                                onClick={handleDiscard}
                                sx={{
                                    marginRight: "16px",
                                    width: "25%",
                                    padding: "0px 16px",
                                    gap: "8px",
                                    borderRadius: "8px",
                                    color: "#000",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 2px 8px 0px #1E07511F",
                                    border: "0px",
                                }}
                            >
                                {"Cancel"}
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{ width: "75%" }}
                                disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
                            >
                                {"Save Entry"}
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default DiaryEntryForm;