import React, {useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PetForm from '../components/Forms/PetForm';
import { paths } from '../data/constants';
import usePetStorage from '../managers/petsManager';

const PetCreateEdit = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [petID, setPetID] = useState(location.state?.petID);

    if (!petID) {
        navigate(paths.petCreate);
    }
    // apply manager
    const {
        pets,
        updatePet,
        addPet,
    } = usePetStorage();

    const pet = Object.values(pets).find((pet) => pet.id === petID) || {};

    const handleSubmit = (values) => {
        if (petID) {
            updatePet(petID, values);
        } else {
            const petID = addPet(values);
            setPetID(petID);
        }
        
        if (petID) {
            navigate(paths.petProfile(petID));
        } else {
            navigate(paths.pets);
        }
    };

    return (
        <PetForm initialValues={pet} onSubmit={handleSubmit} />
    )
};

export default PetCreateEdit;