import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ApiClient from '../utils/client';

const api = new ApiClient();

const usePetStorage = () => {
    const [storedPets, setStoredPets] = useState(
        localStorage.getItem('pets') 
        ? JSON.parse(localStorage.getItem('pets')) 
        : {}
    );

    const getEmail = () => {
        const auth = localStorage.getItem('auth');
        const email = JSON.parse(auth)?.data?.email;
        
        if (!email) throw new Error("Email not found in localStorage");

        return email;
    };

    // Fetch pets from the database by email
    const fetchPetsByEmail = async (email) => {
        try {
            const response = await api.get(`pet/pet/list-by-email?email=${email}`);
            if (response.error) {
                console.error("Error fetching pets from database:", response.error);
                toast.error("Error fetching pets from database");
                return null;
            }

            const pets = response.reduce((acc, pet) => {
                acc[pet.id] = pet;
                return acc;
            }, {});

            setStoredPets(pets); // Update state
            syncLocalStorage(pets); // Sync with localStorage

            return pets;
        } catch (error) {
            console.error("Error fetching pets:", error);
            return null;
        }
    };

    // Sync pet data with localStorage
    const syncLocalStorage = (pets) => {
        try {
            localStorage.setItem('pets', JSON.stringify(pets));
        } catch (error) {
            console.error("Error syncing pets to localStorage:", error);
        }
    };

    const addPet = async (newPet) => {
        try {
            const email = getEmail(); // gets email from local storage
            
            const response = await api.post(`pet/pet/create-by-email/${email}`, newPet);

            if (response.error) {
                console.error("Error adding pet:", response.error);
                toast.error("Error adding pet");
                return;
            }

            if (!response.id) {
                console.error("Error adding pet: Pet ID not found");
                toast.error("Error adding pet");
                return;
            }

            setStoredPets((prevPets) => {
                const newPetId = Object.keys(prevPets).length + 1;
                return {
                    ...prevPets,
                    [newPetId]: {
                        ...newPet,
                        id: newPetId,
                    },
                };
            });

            syncLocalStorage(storedPets); // Sync with localStorage

            refreshPets(); // Refresh pets after adding

            toast.success("Pet added successfully");
        } catch (error) {
            console.error("Error adding pet:", error);
            toast.error("Error adding pet");
        }
    };

    const updatePet = async (petId, newPetData) => {
        try {
            const response = await api.put(`pet/pet/update/${petId}`, newPetData);

            if (response.error) {
                console.error("Error updating pet:", response.error);
                toast.error("Error updating pet");
                return;
            }

            setStoredPets((prevPets) => {
                return {
                    ...prevPets,
                    [petId]: {
                        ...prevPets[petId],
                        ...newPetData,
                    },
                };
            });
            
            syncLocalStorage(storedPets); // Sync with localStorage

            refreshPets(); // Refresh pets after updating

            toast.success("Pet updated successfully");
        } catch (error) {
            console.error("Error updating pet:", error);
            toast.error("Error updating pet");
        }
    };

    const deletePet = async (petId) => {
        try {
            const response = await api.delete(`pet/pet/delete/${petId}`);

            if (response.error) {
                console.error("Error deleting pet:", response.error);
                toast.error("Error deleting pet");
                return;
            }

            refreshPets(); // Refresh pets after deleting

            toast.success("Pet deleted successfully");
        } catch (error) {
            console.error("Error deleting pet:", error);
            toast.error("Error deleting pet");
        }
    };

    const getPetById = (petId) => storedPets[petId] || null;

    const getAllPets = () => Object.values(storedPets);

    const getPetAgeByDOB = (dob) => {
        if (!dob) return '';
        const today = new Date();
        const birthDate = new Date(dob);

        let years = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();

        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) years--;

        const totalDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
        if (years > 1) return `${years} year${years > 1 ? 's' : ''}`;
        if (totalDays > 31) return `${Math.floor(totalDays / 30)} month${Math.floor(totalDays / 30) > 1 ? 's' : ''}`;
        if (totalDays === 1) return '1 day';
        return `${totalDays} days`;
    };

    const refreshPets = async () => {
        try {
            const email = getEmail(); // gets email from local storage
            
            await fetchPetsByEmail(email); // Fetch pets and refresh state
        } catch (error) {
            console.error("Error refreshing pets:", error);
        }
    };

    useEffect(() => {
        refreshPets(); // Initial load
    }, [refreshPets]);

    return {
        pets: storedPets,
        addPet,
        updatePet,
        deletePet,
        getPetById,
        getAllPets,
        getPetAgeByDOB,
    };
};

export default usePetStorage;
