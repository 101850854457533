import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

export default function ResponsiveDatePickers({ name, value, onChange, error, helperText }) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                value={value ? dayjs(value) : null} // Set the date value
                onChange={(newValue) => onChange(name, newValue ? newValue.toISOString() : '')} // Update Formik's value
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        error={Boolean(error)}
                        helperText={helperText}
                    />
                )}
                // maxDate={new Date()} // Disable future dates
                sx={{
                    width: '100%',
                    width: '100%',
                    borderRadius: '8px',
                    border: '2px solid transparent',
                    background: 'var(--Light-Grey, #F6F7FB)',
                    '& .MuiInputBase-input': {
                        fontSize: '16px',
                        fontWeight: 500,
                        lineHeight: '24.8px',
                        textAlign: 'left',
                        color: '#1F1F2A',
                    },

                }}

            />
        </LocalizationProvider>
    );
}
