import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DiaryEntryForm from '../components/Forms/DiaryEntryForm';
import { paths, apiEndpoints } from '../data/constants';
import useStorage from '../managers/dataManager';

const DiaryEntryCreate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { profileId } = useParams();
    const [entryID, setEntryID] = useState(location.state?.entryID);

    // Apply diary manager
    const {
        data: entries,
        addItem: addEntry,
        updateItem: updateEntry,
    } = useStorage('diary', apiEndpoints.diary);

    let entry = Object.values(entries).find((entry) => entry.id === entryID) || {};
    if (!entry.petID) {
        console.log('setting petID', profileId);
        entry.petID = profileId;
    }

    const handleSubmit = (values) => {
        if (entryID) {
            updateEntry(entryID, values);
        } else {
            const newEntryID = addEntry(profileId, values);
            setEntryID(newEntryID);
        }
        
        if (entryID) {
            navigate(paths.petEntryEdit(profileId, entryID));
        } else {
            navigate(paths.petProfile(profileId));
        }
    };

    return (
        <DiaryEntryForm initialValues={entry} onSubmit={handleSubmit} />
    );
};

export default DiaryEntryCreate;