import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import PetProfileDivider from '../../Dividers/PetProfileDivider';
import DropzoneImage from '../../DropImage/DropzoneImage';
import ResponsiveDatePickers from '../../DatePicker/datePicker';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        paddingBottom: '8px',
        gap: '8px',
    },
    iconText: {
        '&.MuiTypography-root': {
            fontSize: '14px',
            fontWeight: 600,
            color: "#1F1F2A",
            lineHeight: '21.7px',
        }
    },
    icon: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
    },
    inputTextBox: {
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        border: '2px solid transparent',
        background: 'var(--Light-Grey, #F6F7FB)',
        '& .MuiInputBase-input': {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    textIdFields: {
        textAlign: 'left',
        color: '#7D7D97',
        fontSize: 12,
        lineHeight: '18.6px',
        fontWeight: 600,
        marginBottom: '8px',
    },
    chip: {
        height: '24px',
        borderRadius: '32px',
        fontSize: '12px',
        lineHeight: '18.6px',
        fontWeight: 600,
        background: 'var(--Light-Grey, #F6F7FB) !important',
        width: 'fit-content',
    },
    detailSection: {
        marginBottom: '16px',
    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    chipLabel: {
        "&.MuiChip-label": {
            padding: '8px',
        },
    },
    breedGroup: {
        display: 'flex',
        gap: '8px',
        width: '100%',
    },
}));


const PetGeneralDetailsForm = ({ formik, ref }) => {
    const classes = useStyles();

    return (
        <div style={{ width: "100%" }} ref={ref}>
            <div className={classes.detailSectionTitle}>
                <Typography align="left" className={classes.detailTitle}>
                    Basic information
                </Typography>
            </div>

            <DropzoneImage formik={formik} isEditing={true}/>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Name
                    </Typography>
                </div>
                <TextField
                    name="name"
                    value={formik.values.name}
                    placeholder='e.g. Max'
                    onChange={formik.handleChange}
                    className={classes.inputTextBox}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
            </div>
            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Breed
                    </Typography>
                </div>
                <div className={classes.breedGroup}>
                    <TextField
                        name="breed"
                        value={formik.values.breed}
                        onChange={formik.handleChange}
                        placeholder='e.g. British Shorthair'
                        className={classes.inputTextBox}
                        sx={{ width: "70%" }}
                        error={formik.touched.breed && Boolean(formik.errors.breed)}
                        helperText={formik.touched.breed && formik.errors.breed}
                    />
                    <TextField
                        className={`${classes.input} ${classes.ageInputDropdown}`}
                        select
                        name="pet_type"
                        value={formik.values.pet_type || "cat"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        color="primary"
                        sx={{
                            width: "30%",
                            boxShadow: '0px 2px 8px 0px #1E07511F',
                        }}
                        placeholder="Select"
                        error={formik.touched.pet_type && Boolean(formik.errors.pet_type)}
                        helperText={formik.touched.pet_type && formik.errors.pet_type}
                    >
                        {["Cat", "Dog", "Bird", "Rabbit", "Hamster", "Fish", "Reptile", "Amphibian", "Other"].map((type) => (
                            <MenuItem key={type} value={type.toLowerCase()}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Gender
                    </Typography>
                </div>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    row
                    onChange={
                        (e) => formik.setFieldValue('sex', e.target.value)
                    }
                    value={formik?.values?.sex ?? "female"}
                >
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                </RadioGroup>
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Date of birth
                    </Typography>
                </div>
                <ResponsiveDatePickers
                    name="date_of_birth"
                    value={formik.values.date_of_birth || null}
                    onChange={(key, value) => {
                        // Ensure the value is in the correct format, handling timezone
                        const formattedValue = value ? new Date(value).toLocaleDateString('en-CA') : null;
                        formik.setFieldValue(key, formattedValue);
                    }}
                    error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                    helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                />
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Lives in
                    </Typography>
                </div>
                <TextField
                    name="address"
                    value={formik.values.address}
                    onChange={
                        (e) => formik.setFieldValue("address", e.target.value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder='e.g. Dubai, UAE'
                    className={classes.inputTextBox}
                    error={formik.touched.address && Boolean(formik.errors.address)}
                    helperText={formik.touched.address && formik.errors.address}
                />
            </div>

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        ID & health essentials
                    </Typography>
                </div>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox checked={formik.values.is_microchipped} onChange={
                                (e) => formik.setFieldValue("is_microchipped", e.target.checked)
                            } name="is_microchipped" />
                        }
                        label="Microchipped"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={formik.values.is_vaccinated} onChange={
                                (e) => formik.setFieldValue("is_vaccinated", e.target.checked)
                            } name="is_vaccinated" />
                        }
                        label="Vaccinated"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={formik.values.is_neutered} onChange={
                                (e) => formik.setFieldValue("is_neutered", e.target.checked)
                            } name="is_neutered" />
                        }
                        label="Spayed/Neutered"
                    />
                </FormGroup>
            </div>


            {formik?.values?.is_microchipped && (

                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <Typography align="left" className={classes.iconText}>
                            Microchip number
                        </Typography>
                    </div>
                    <TextField
                        name="microchip_number"
                        value={formik.values?.extra_data?.microchip_number}
                        onChange={
                            (e) => formik.setFieldValue("extra_data.microchip_number", e.target.value)
                        }
                        className={classes.inputTextBox}
                        placeholder='e.g. 123456789'
                    />
                </div>
            )}

            <div className={classes.detailSection}>
                <div className={classes.iconContainer}>
                    <Typography align="left" className={classes.iconText}>
                        Animal ID
                    </Typography>
                </div>
                <TextField
                    name="animal_id"
                    value={formik.values?.extra_data?.animal_id}
                    onChange={
                        (e) => formik.setFieldValue("extra_data.animal_id", e.target.value)
                    }
                    className={classes.inputTextBox}
                    placeholder='e.g. 123456789'

                />
                <PetProfileDivider />
                <div className={classes.detailSectionTitle}>
                    <Typography align="left" className={classes.detailTitle}>
                        Personality and preferences
                    </Typography>
                </div>

                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <img src={`${process.env.PUBLIC_URL}/profileIcons/psychology.svg`} alt="brain" />
                        <Typography align="left" className={classes.iconText}>
                            Personality
                        </Typography>
                    </div>
                    <TextField
                        multiline
                        name="personality"
                        value={formik.values?.extra_data?.personality}
                        onChange={
                            (e) => formik.setFieldValue("extra_data.personality", e.target.value)
                        }
                        className={classes.inputTextBox}
                        placeholder='e.g. Expressive and vocal (only when she wants something)'
                    />
                    {/* personality: initialValues?.personality || '',
                favorite_food: initialValues?.favorite_food || '',
                favorite_activity: initialValues?.favorite_activity || '',
                biggest_fear: initialValues?.biggest_fear || '', */}
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <img src={`${process.env.PUBLIC_URL}/profileIcons/favFood.svg`} alt="food" />
                        <Typography align="left" className={classes.iconText}>
                            Favourite food
                        </Typography>
                    </div>
                    <TextField
                        multiline
                        name="favorite_food"
                        value={formik.values?.extra_data?.favorite_food}
                        onChange={
                            (e) => formik.setFieldValue("extra_data.favorite_food", e.target.value)
                        }
                        className={classes.inputTextBox}
                        placeholder='e.g. Tuna'
                    />
                </div >
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <img src={`${process.env.PUBLIC_URL}/profileIcons/landscape.svg`} alt="activity" />
                        <Typography align="left" className={classes.iconText}>
                            Favourite activity
                        </Typography>
                    </div>
                    <TextField
                        multiline
                        name="favorite_activity"
                        value={formik.values?.extra_data?.favorite_activity}
                        onChange={
                            (e) => formik.setFieldValue("extra_data.favorite_activity", e.target.value)
                        }
                        className={classes.inputTextBox}
                        placeholder='e.g. Playing with a ball'
                    />
                </div>
                <div className={classes.detailSection}>
                    <div className={classes.iconContainer}>
                        <img src={`${process.env.PUBLIC_URL}/profileIcons/fear.svg`} alt="fear" />
                        <Typography align="left" className={classes.iconText}>
                            Biggest fear
                        </Typography>
                    </div>
                    <TextField
                        multiline
                        name="biggest_fear"
                        value={formik.values?.extra_data?.biggest_fear}
                        onChange={
                            (e) => formik.setFieldValue("extra_data.biggest_fear", e.target.value)
                        }
                        className={classes.inputTextBox}
                        placeholder='e.g. Vacuum cleaner'
                    />
                </div>
                <PetProfileDivider />

            </div>
        </div>
    );
};

export default PetGeneralDetailsForm;
