import React, { useState, useRef } from 'react';
import PetGeneralDetails from '../components/PetProfileSections/PetGeneralDetails';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import usePetStorage from '../managers/petsManager';
import PetProfileDivider from '../components/Dividers/PetProfileDivider';
import Header from '../components/Header/header';
import PetProfileHeader from '../components/Header/petProfileHeader';
import PetDiary from '../components/PetProfileSections/PetDiary';
import DefaultSpeedDial from '../components/SpeedDial/defaultSpeedDial';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { useNavigate } from 'react-router-dom';
import { paths } from '../data/constants';

const PetProfile = () => {
    const { profileId } = useParams();
    const navigate = useNavigate();
    const actions = [
        { icon: <MedicalServicesIcon />, name: 'New Entry', handler: () => {navigate(paths.petEntryCreate(profileId))} },

    ];
    const [tabIndex, setTabIndex] = useState(0);

    // scroll to top when component is mounted
    const aboutRef = useRef(null);
    const parentsRef = useRef(null);
    const medicalRef = useRef(null);
    // Function to handle tab change and scroll to section
    const handleTabChange = (index) => {
        setTabIndex(index);
        const sectionRefs = [aboutRef, parentsRef, medicalRef];
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' });
    };


    const { getPetById } = usePetStorage();
    
    const pet = getPetById(profileId);

    return (
        <div>
            <Header>
                <PetProfileHeader
                    pet={pet}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    onTabChange={handleTabChange} // Pass the handler
                />
            </Header>
            <Container maxWidth="md" sx={{ padding: '24px 20px' }}>
                <div ref={aboutRef}>
                    <PetGeneralDetails pet={pet} />
                    <PetProfileDivider />
                </div>
                <div ref={parentsRef}>
                    {/* Parents Section */}
                </div>
                <div ref={medicalRef}>
                    {/* Medical Section */}
                    <PetDiary pet={pet}/>
                </div>
            </Container>
            <DefaultSpeedDial actions={actions} />
        </div>
    );
};

export default PetProfile;