import React from 'react';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, Container } from '@mui/material';
import useStorage from '../../managers/dataManager';
import DiaryCard from '../Medical/DiaryCard';
import { diaryEntryTypes } from '../../data/constants';
const useStyles = makeStyles((theme) => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        padding: '16px',
    },
    formItem: {
        marginBottom: '16px',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        padding: '8px',
        gap: '8px',
    },
    iconText: {
        fontSize: '14px',
        fontWeight: 600,
        color: "#1F1F2A",
        lineHeight: '21.7px',
    },
    detailSection: {
        marginBottom: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',

    },
    detailSectionTitle: {
        marginBottom: '24px',
    },
    detailTitle: {
        "&.MuiTypography-root": {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '21.94px',
            textAlign: 'left',
            color: '#1F1F2A',
        },
    },
    newPetButtonWrapper: {
        // center the button at the bottom of the screen
        display: 'flex',
        position: 'fixed',
        bottom: '0dvh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '16px 24px',
        width: "fit-content",
        justifyContent: "center",
        alignItems: 'center',

    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '60vh',
        "& .MuiGrid-root": {
            paddingTop: '16px',
            marginBottom: 'auto',
        },
    },
    noPetsContainer: {
        // absolute center
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        display: 'flex',
        // take all available space
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#7D7D97',

    },
}));


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Grid2 container spacing={2} sx={{
                    width: '100%',
                }} alignContent={'center'} flexDirection={'column'}>
                    {children}
                </Grid2>
            )}
        </div>
    );
}

const DefaultEmptyDiary = () => {
    const classes = useStyles();
    return (
        <div className={classes.noPetsContainer}>
            <img src={`${process.env.PUBLIC_URL}/logo-gray.svg`} alt='logo' />
            <Typography>No pets yet</Typography>
        </div>
    );
}

const PetDiary = ({ pet }) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    // pet id matches the medical history id
    const {
        getItemById: getDiaryItemById,
    } = useStorage('diary');

    const currentDiaryEntries = getDiaryItemById(pet?.id) || {
        "1": {
        "category": "health-and-wellness",
        "description": "Went for a fancy haircut",
        "date": "2024-12-14",
        "photos": {
            "1": "https://treet-v1.s3.ap-southeast-1.amazonaws.com/user-upload/20241214/08334a70-ab18-428a-8a2f-b1533be94d8b-Omar%20Rassem%20port.jpg",
            "2": "https://treet-v1.s3.ap-southeast-1.amazonaws.com/user-upload/20241214/08334a70-ab18-428a-8a2f-b1533be94d8b-Omar%20Rassem%20port.jpg"
        },
        "location": "",
        "extra_data": {},
        "diary_items": [
          {
            "type": "reminder",
            "status": "pending",
            "title": "Remind me to take her back to the vet",
            "location": "some place far far away",
            "date": "2024-12-27",
            "time": "17:00",
            "extra_data": {}
          }
        ]
        },
      };

    return (
        <div className={classes.formContainer}>
            <div className={classes.detailSection}>
                <div className={classes.detailSectionTitle}>
                    <Typography align="left" className={classes.detailTitle}>
                        Diary entries
                    </Typography>
                </div>

                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Diary tabs"
                    // no indicator for the tabs
                    indicatorColor={'none'}
                    orientation='horizontal'
                    variant='scrollable'
                    textColor="#1F1F2A"
                    allowScrollButtonsMobile
                    // Change the box shadow of the tabs on selection
                    sx={{
                        "& .Mui-selected": {
                            boxShadow: '0px 2px 7.1px 0px #00000026',
                            borderRadius: '8px ',
                            margin: '2px',
                        },
                    }}
                >
                    {['All', ...Object.values(diaryEntryTypes)].map((title, index) => (
                        <Tab key={index} label={title} />
                    ))}
                </Tabs>
            </div>

            {/* map panels */}

            {[['all', 'All'], ...Object.entries(diaryEntryTypes)].map(([key, title], index) => (
                <TabPanel key={index} value={selectedTab} index={index} >
                    <div className={classes.contentContainer}>
                        {Object.keys(currentDiaryEntries).length > 0 ? Object.values(currentDiaryEntries).map((entry, index) => {
                            if (title === 'All' || entry.category === key) {
                                return (
                                    <DiaryCard key={index} diaryEntries={entry} />
                                )
                            }
                        }) : (<DefaultEmptyDiary />)}
                    </div>
                </TabPanel>
            ))}

        </div>
    );
};

export default PetDiary;