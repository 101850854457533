import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Box } from '@mui/material';

const DefaultSpeedDial = ({ actions }) => {

    // actions is an array of objects with the following structure
    // {
    //     name: string,
    //     icon: JSX,
    //     handler: function
    // }

    return (
        <Box 
            sx={{ 
                transform: 'translateZ(0px)', 
                flexGrow: 1, 
                zIndex: 10000, 
                position: 'fixed', 
                bottom: 20, 
                right: 16 
            }}
        >
            <SpeedDial
                ariaLabel="SpeedDial example"
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        // tooltipOpen
                        tooltipTitle={action.name}
                        onClick={action.handler}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default DefaultSpeedDial;
