import React from 'react';
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from '@mui/material';
import Header from '../components/Header/header.jsx';
import PetsHeader from '../components/Header/petsHeader.jsx';
import usePetStorage from '../managers/petsManager.jsx';
import { paths } from '../data/constants.js';
import PetList from '../components/PetsList/PetList.jsx';
import UpcomingList from '../components/UpcomingList/UpcomingList.jsx';
import AboutMeEditForm from '../components/About/AboutMeEdit.jsx';

const useStyles = makeStyles((theme) => ({
    title: {
        '&.MuiTypography-root': {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '24.38px',
            textAlign: 'left',
            color: theme.palette.text.primary,
        },
    },
    petsTitleContainer: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    petsValue: {
        color: '#7019FF',
        paddingLeft: '12px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    navContainer: {
        backgroundColor: '#f8f9fa',
        boxShadow: '0px 4px 15px 0px #0000000D',
        borderBottom: '1px solid rgba(227, 232, 255, 1)',
        width: '100%',
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemsContainer: {
        // maxWidth: '900px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));


const PetHome = () => {
    // Styles
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const { isNewPetOwner } = location.state || {}; // Accessing state safely
    const clearNewParentState = () => {
        navigate(paths.pets, { state: null, replace: true });
    };

    // States
    const {
        pets,
        updatePet,
        deletePet,
    } = usePetStorage();

    const [tabIndex, setTabIndex] = React.useState(0);

    // Functions
    const handlePetDelete = (petId) => {
        deletePet(petId);
        toast.success('Pet deleted');
    };

    const handlePetNameUpdate = (petId, newName) => {
        updatePet(petId, { name: newName });
    };

    return (
        <>
            <Header navContainer={classes.navContainer} itemsContainer={classes.itemsContainer}>
                <PetsHeader tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </Header>
            <Container maxWidth="lg">
                <div className={classes.contentContainer}>
                    {tabIndex === 0 ? (
                        <PetList 
                            pets={pets}
                            handlePetDelete={handlePetDelete}
                            handlePetUpdate={handlePetNameUpdate}
                            isNewPetOwner={isNewPetOwner}
                            clearNewParentState={clearNewParentState}
                        />
                    ) : tabIndex === 1 ? (
                        <UpcomingList />
                    ) : tabIndex === 2 ? (
                        // coming soon in the middle
                        <AboutMeEditForm  />
                    ) : setTabIndex(0)}
                </div>
            </Container>
        </>
    );
};

export default PetHome;
