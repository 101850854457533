import React, {useState} from 'react';
import { Card, CardContent, CardMedia, Typography, Dialog } from '@mui/material';
import { DiaryCardTagsStyle, diaryEntryTypes } from '../../data/constants.js';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { paths } from '../../data/constants.js';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ReusableMenuPets from '../Menus/ReusableThreeMenuPets.jsx';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
        // maxHeight: 200,
        padding: '16px',
        borderRadius: '12px !important',
        marginBottom: '16px',
        boxShadow: '0px 4px 19.8px 0px #0000001A',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        // space between
        justifyContent: 'space-between',
        // height: 25,
    },
    petName: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '8px',
        "&.MuiTypography-root": {
            // 16px
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '24.8px',
            color: "#1F1F2A"
        },
    },
    content: {
        display: 'flex',
        // take all available space
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'start',
        cursor: 'pointer',
        // maxHeight: 64,
        "&.MuiCardContent-root": {
            padding: "0px !important",
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '6px',
        justifyContent: 'center',
    },
    chip: {
        height: 27,
        borderRadius: '6px !important',
        pointerEvents: 'none',
        "&.MuiChip-root": {
            maxWidth: 220,
            width: 'fit-content',
            padding: '6px 8px',
            fontSize: '0.75rem', // 12px
            fontWeight: 600,
            lineHeight: '14.63px',
        },
        "& .MuiChip-label": {
            padding: 0,
        },
    },
    moreIcon: {
        // fix to top right with 12px padding top and 8px padding right from card
        "&.MuiIconButton-root": {
            position: 'absolute',
            top: 12,
            right: 8,
            padding: 0,
        },

    },
    carousel: {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        width: '100%',
        height: 'fit-content',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'fit-content',
        marginBottom: '16px',
    },
    description: {
        "&.MuiTypography-root": {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24.8px',
            color: "#1F1F2A",
        },
    },
}));

const DiaryCard = ({ diaryEntries }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { photos, date, description, category, entrySubType } = diaryEntries;

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedImage(null);
    };

    const menuItems = [
        {
            label: 'Edit Entry',
            icon: <BorderColorOutlinedIcon />,
            action: () => { },
        },
        {
            label: 'Delete Entry',
            icon: <DeleteOutlinedIcon />,
            action: () => { }
        },
    ];
    return (
        <Card fullWidth className={classes.card}>
            <CardContent className={classes.content}>

                <div className={classes.section}>
                    {/* entry type as a chip */}
                    <Chip
                        label={diaryEntryTypes[category]}
                        size="small"
                        style={DiaryCardTagsStyle['type'][category] || DiaryCardTagsStyle['default']}
                        className={classes.chip}
                    />
                </div>
                <div className={classes.section}>
                    <Typography className={classes.description}>
                        {description}
                    </Typography>
                </div>
                {photos && Object.keys(photos).length > 0 && (
                    <div className={classes.section}>
                        <div className={classes.carousel}>
                            {Object.values(photos).slice(0, 3).map((photo, index) => (
                                <CardMedia
                                    key={index}
                                    component="img"
                                    // height="140"
                                    // width="140"
                                    image={photo}
                                    alt={`Medical ${index}`}
                                    style={{
                                        margin: '10px 0',
                                        width: '93px',
                                        height: '88px',
                                        // padding: '28px',
                                        borderRadius: '8px',
                                        border: '1px solid #E3E8FF',
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Stop card navigation
                                        handleImageClick(photo);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
                <Typography color="textPrimary">
                    {/* Date example: 22 November 2024 */}
                    {new Date(date).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                    })
                    }
                </Typography>
                <IconButton
                    className={classes.moreIcon}
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent the click from propagating to the card
                        // Add any functionality to open the menu here
                    }}
                >
                    <ReusableMenuPets pet={diaryEntries} menuItems={menuItems} />
                </IconButton>
                {/* Dialog to show enlarged image */}
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Zoomed In"
                            className={classes.dialogImage}
                        />
                    )}
                </Dialog>
            </CardContent>
        </Card>
    );
};

export default DiaryCard;