import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import PetCard from './PetCard.jsx'; // Your pet card component
import CustomTreetButtonHollow from '../Buttons/CustomTreetButtonHollow.jsx'; // Your custom button component
import Typography from '@mui/material/Typography';
import MessageDrawer from '../Menus/MessageDrawer.jsx';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../data/constants.js';
const useStyles = makeStyles((theme) => ({
    contentContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        "& .MuiGrid-root": {
            paddingTop: '16px',
            marginBottom: 'auto',
        },
    },
    petsListContainer: {
        "&.MuiGrid-root": {
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'flex-start',

        },
        paddingBottom: '70px',
    },
    petsValue: {
        color: 'var(--Bright-Purple, #7019FF)',
        paddingLeft: '12px',
    },

    newPetButtonWrapper: {
        // center the button at the bottom of the screen
        display: 'flex',
        position: 'fixed',
        bottom: '0dvh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '16px 24px',
        width: "fit-content",
        justifyContent: "center",
        alignItems: 'center',

    },
    noPetsContainer: {
        // absolute center
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content',
        width: 'fit-content',
        color: '#7D7D97',
    },
}));

const PetList = ({ pets, handlePetDelete, handlePetUpdate, isNewPetOwner = false, clearNewParentState }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [newPetOwnerMsg, setNewPetOwnerMsg] = useState(false);
    
    useEffect(() => {
        if (!isNewPetOwner) {
            return;
        }
        // wait 1 second before showing the message
        setTimeout(() => {
            setNewPetOwnerMsg(true);
        }, 500);

    }, [isNewPetOwner]);

    useEffect(() => {
        // if there's no auth in localStorage, redirect to login
        const auth = localStorage.getItem('auth');
        if (!auth) {
            navigate(paths.login);
        }

        const authJSON = JSON.parse(auth || '{}');
        if (!authJSON.state || authJSON.state !== 'logged-in') {
            navigate(paths.login);
        }
    }, []);

    useEffect(() => {
        // Re-render the component when `pets` changes
    }, [pets]);

    const handleCloseNewParentMsg = () => {
        // clear the state after showing the message
        clearNewParentState();
        setNewPetOwnerMsg(false);
    }

    const handleCreateNewPetBtn = () => {
        // Navigate to the pet create page with null petID
        navigate(paths.petCreate, { state: { petID: null } });
    }
    return (
        <div className={classes.contentContainer}>
            <div className={classes.newPetButtonWrapper}>
                <CustomTreetButtonHollow
                    icon="Add"
                    onClick={handleCreateNewPetBtn}
                    variant="filled"
                    type="pet"
                    style={{
                        backgroundColor: 'var(--White, #FFFFFF)',
                        boxShadow: '0px 2px 35px 0px #00000026',
                        maxWidth: '132px',
                        maxHeight: '40px',
                        padding: '0px 20px 0px 12px',
                        gap: '6px',
                        borderRadius: '55px',

                    }}
                >
                    New Pet
                </CustomTreetButtonHollow>
            </div>
            {Object.values(pets).length !== 0 ? (
                <Grid container className={classes.petsListContainer}>
                    {Object.values(pets)?.map((pet) => {
                        if (!pet?.id) {
                            return null;
                        }
                        return (
                            <PetCard
                                key={pet.id}
                                pet={pet}
                                handlePetDelete={() => handlePetDelete(pet.id)}
                                handlePetUpdate={handlePetUpdate}
                            />

                        )
                    })}
                </Grid>
            ) :
                <div className={classes.noPetsContainer}>
                    <img src={`${process.env.PUBLIC_URL}/logo-gray.svg`} alt='logo' />
                    <Typography>No pets yet</Typography>
                </div>
            }
            {
                <MessageDrawer
                    open={newPetOwnerMsg}
                    onClose={handleCloseNewParentMsg}
                    imageSrc={`${process.env.PUBLIC_URL}/Welcome Message Drawer.png`}
                    text="Congratulations on your first pet profile! 🎉"
                    subText={"Tap on the Edit icon to continue customising your pet profile."}
                />
            }
        </div>


    );
};


export default PetList;
